import React from "react";
import styling from "./Faq.module.sass";

export const Faq = () => {
  return (
    <div className={styling.Faq} id="step-faq">
      <h1>Frequently asked Questions</h1>

      <div className={styling.list}>
        <div className={styling.entry}>
          <div className={styling.question}>When can I start using Draftical?</div>
          <div className={styling.answer}>
            <div>
              We're doing a closed Beta run right now, which is only available to select people. We hope to release the
              software with all features in early 2022. If you sign up for our email newsletter, we can tell you the
              minute it gets released.
            </div>
            <div>
              There is going to be an open Beta release, coming Q4 in 2021. So if you want to jump in early, sign up for
              the newsletter.
            </div>
          </div>
        </div>
        <div className={styling.entry}>
          <div className={styling.question}>How safe is my data and where is it stored?</div>
          <div className={styling.answer}>
            Once Draftical is going public, the app will routinely make backups of all of your written content. This
            will be saved to a completely independent backup location. We also only use data servers in Europe, so
            nothing gets transferred to US servers or other non-EU countries.We have also done everything in our power
            to make your data save from other users and malicious third party attackers. We also only gather data about
            you, that we really need (like your email), so we have the minimum amount of data about you. We are never
            going to sell user data, we only earn money with your subscriptions.
          </div>
        </div>
        <div className={styling.entry}>
          <div className={styling.question}>How can I cancel Draftical and what happens to my data?</div>
          <div className={styling.answer}>
            If you're paying for the monthly plan (we plan to integrate a yearly plan), you can cancel from month to
            month. Your data will NOT be deleted straight away when you're stopping your subscription (except if you
            requested it through a GDPR takedown request). We will have a grace period of 1 year, after you cancelled
            your plan. If we're planning on deleting your data after that period, we will notify you and give you time
            to export your data. During the grace period, you will still be able to access your story data, but you will
            not be able to change anything about it anymore. We're also going to integrate an export tool, so that you
            can export your data to keep it safe with you.
          </div>
        </div>
        <div className={styling.entry}>
          <div className={styling.question}>Do you plan to include feature x?</div>
          <div className={styling.answer}>
            We'd love to hear from you regarding any feature that you're thinking about! This software is growing with
            its users and with the feedback those users provide. So if you have any idea, shoot us an email at
            info@icebearlabs.com and we will note it down for sure. We can't promise all feature will be included, but
            we will certainly think about all of them.
          </div>
        </div>
        <div className={styling.entry}>
          <div className={styling.question}>How can I pay for Draftical?</div>
          <div className={styling.answer}>
            At full release, you'll be able to subscribe and pay by Paypal and credit card. We might have more payment
            methods available, but it's not certain, yet.
          </div>
        </div>
        <div className={styling.entry}>
          <div className={styling.question}>How many stories can I draft at the same time?</div>
          <div className={styling.answer}>
            We don't have any maximum defined right now. If you want to plan 100 stories, you can do that on both payed
            plans right now.
          </div>
        </div>
        <div className={styling.entry}>
          <div className={styling.question}>Can I add images or music to my scenes?</div>
          <div className={styling.answer}>
            You cannot add images or music to the scenes right now, BUT we're working on making both possible. Images
            will be available in the open beta, we're not sure about other file types, yet. We'll keep you updated on
            that.
          </div>
        </div>
        <div className={styling.entry}>
          <div className={styling.question}>Can I import existing data, like a DOC file?</div>
          <div className={styling.answer}>
            To be honest, importing tools are a lot of work and we don't plan on creating one for Draftical right now.
            So if you have already written down parts of your story planning, you might have to retype it into
            Draftical. We will provide an export tool though, so you won't have to go through the same, the other way
            around.
          </div>
        </div>
      </div>
    </div>
  );
};
