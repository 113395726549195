import React from "react";
import style from "./App.module.sass";
import { Header, Showcase, CallToAction, MessageDisplay, Pricing, Faq, Footer } from "./components";

const App = () => {
  return (
    <div className={style.App}>
      <Header />
      <CallToAction />
      <Showcase />
      <CallToAction />
      <Pricing />
      <Faq />
      <h1>Ready to get started?</h1>
      <CallToAction />
      <Footer />
      <MessageDisplay />
    </div>
  );
};

export default App;
