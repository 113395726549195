import classNames from "classnames";
import React from "react";
import styling from "./Footer.module.sass";

export const Footer = () => {
  return (
    <div className={styling.Footer}>
      <div className={classNames(styling.subContainer)}>
        <h2>Social Media</h2>
        <a target="_blank" rel="noreferrer" href="https://www.twitter.com/getDraftical">
          Twitter
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/icebearlabs">
          Instagram
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.icebearlabs.com/blog">
          Blog
        </a>
      </div>
      <div className={classNames(styling.subContainer)}>
        <h2>Contact</h2>
        <h4>info @ icebearlabs . com</h4>
      </div>
      <div className={classNames(styling.subContainer)}>
        <h2>Legal Information</h2>
        <a target="_blank" rel="noreferrer" href="https://www.icebearlabs.com/imprint">
          Imprint
        </a>
        <a target="_blank" rel="noreferrer" href="https://icebearlabs.com/data-privacy-policy">
          Privacy Policy
        </a>
      </div>
    </div>
  );
};
