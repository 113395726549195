import React from "react";
import style from "./Header.module.sass";
import { ReactComponent as Wave } from "../../assets/icons/wave.svg";
import useWindowDimensions from "../../utils";

export const Header = () => {
  const { height, width } = useWindowDimensions();

  const isMobile = width < 800;

  const scrollTo = (elID) => {
    const element = document.getElementById(elID);
    element && element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={style.Header}>
      {isMobile ? (
        <header className={style.mobileHeader}>
          <label>Draftical</label>
          <a className={style.link} href="https://app.draftical.de">
            Login
          </a>
        </header>
      ) : (
        <>
          <Wave className={style.wave} />
          <div className={style.title}>
            <span className={style.left}>
              Draftical
              <a href="https://www.icebearlabs.com" className={style.subTitle}>
                by Ice Bear Labs
              </a>
            </span>
            <div className={style.menu}>
              <div className={style.links}>
                <span className={style.link} onClick={() => scrollTo("step-showcase")}>
                  Features
                </span>
                <span className={style.link} onClick={() => scrollTo("step-pricing")}>
                  Pricing
                </span>
                <span className={style.link} onClick={() => scrollTo("step-faq")}>
                  FAQ
                </span>
                <a className={style.link} href="https://app.draftical.de">
                  Login
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      <h1>A story planning tool to craft your story</h1>
      <p>
        Planning a story takes a lot of work and time. Draftical gives you the tools to create your first draft scene by
        scene, all safely secured in the cloud.
      </p>
      <h3>We are in Beta right now. Version 1 will be released in early 2022</h3>
    </div>
  );
};
