import React, { useEffect } from "react";
import style from "./Modal.module.sass";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

// Needs title, onExit, children
export const Modal = (props) => {
  const handleClose = () => {
    props.onExit();
  };

  return (
    <div className={classNames(style.Modal, { [style.Modal__show]: props.show })}>
      <div className={style.container}>
        <div className={style.header}>
          <div className={style.title}>{props.title}</div>
          <div className={style.closeIcon} onClick={handleClose}>
            <FontAwesomeIcon className={style.icon} icon={faTimes} />
          </div>
        </div>
        <div className={style.content}>{props.children}</div>
      </div>
    </div>
  );
};
