import produce from "immer";

export const messageActionTypes = {
  ADD_MESSAGE: "ADD_MESSAGE",
  REMOVE_MESSAGE: "REMOVE_MESSAGE"
};

export const MessageType = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  SUCCESS: "SUCCESS"
};

const initialState = {
  messages: []
};

export const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case messageActionTypes.ADD_MESSAGE:
      return produce(state, (draftState) => {
        draftState.messages.push(action.payload.message);
      });
    case messageActionTypes.REMOVE_MESSAGE:
      return produce(state, (draftState) => {
        draftState.messages = draftState.messages.filter((message) => message.id !== action.payload.id);
      });
    default:
      return state;
  }
};
