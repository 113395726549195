import React from "react";
import style from "./Showcase.module.sass";
import sceneScreenshot from "../../assets/pics/scene_screenshot.png";
import characterScreenshot from "../../assets/pics/characters_screenshot.png";
import locationScreenshot from "../../assets/pics/locations_screenshot.png";
import itemScreenshot from "../../assets/pics/items_screenshot.png";
import characterCreation from "../../assets/pics/character_creation.png";
import moveScenesGif from "../../assets/pics/move_sceens.gif";

export const Showcase = () => {
  return (
    <div className={style.Showcase} id="step-showcase">
      <h2>Here is how Draftical works</h2>
      <img src={sceneScreenshot} alt="scene screenshot showcasing how scenes work" />
      <p>You create a scene and then add everything that happens within that scene to your content tab.</p>

      <img src={characterScreenshot} alt="scene screenshot showcasing how character tab works" />
      <p>You can add your characters to the scene or create new ones right on the spot.</p>

      <img src={locationScreenshot} alt="scene screenshot showcasing how location tab works" />
      <p>
        You can also add the location the scene takes place at. Multiple locations are also supported, in case your
        characters travel somewhere.
      </p>

      <img src={itemScreenshot} alt="scene screenshot showcasing how item tab works" />
      <p>And lastly you can add important items to your scene, that are relevant for the characters and your story.</p>

      <h3>You can also create all of these objects independently and add them to a scene later.</h3>
      <div className={style.addedBoxShadow}>
        <img src={characterCreation} alt="screenshot of the character creation" />
      </div>

      <h2 style={{ marginTop: "40px", marginBottom: "40px" }}>
        You can also move scenes around, so you can always change your structure
      </h2>
      <img className={style.gif} src={moveScenesGif} alt="gif showing how to move a scene in Draftical" />
    </div>
  );
};
