import React, { useState } from "react";
import style from "./CallToAction.module.sass";
import { Modal } from "../";
import { MailjetSubscribeFrame } from "./mailjetSubscriber";

export const CallToAction = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={style.CallToAction}>
      <button onClick={() => setShowModal(true)}>Sign up to learn more and get access</button>
      <Modal show={showModal} title="Subscribe to the Newsletter" onExit={() => setShowModal(false)}>
        <MailjetSubscribeFrame />
      </Modal>
    </div>
  );
};
