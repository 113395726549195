import React, { useState } from "react";
import styling from "./Pricing.module.sass";
import { ReactComponent as TickSvg } from "../../assets/icons/tick.svg";
import { MailjetSubscribeFrame } from "../call-to-action/mailjetSubscriber";
import { Modal } from "../modal/Modal";

export const Pricing = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styling.Pricing} id="step-pricing">
      <h1>Pricing</h1>
      <p>
        We are keeping it simple. There a two tiers, that you can freely chose from. You can also change your
        subscription from month to month.
      </p>
      <div className={styling.list}>
        <div className={styling.container}>
          <div className={styling.top}>
            <h1>Standard</h1>
            <h2>5€ / Month</h2>
            <div className={styling.feature}>
              <TickSvg />
              <span>Create scenes</span>
            </div>
            <div className={styling.feature}>
              <TickSvg /> <span>Add characters, item and locations</span>
            </div>
            <div className={styling.feature}>
              <TickSvg /> <span>Save everything to the cloud</span>
            </div>
          </div>
          <button onClick={() => setShowModal(true)}>Sign up</button>
        </div>
        <div className={styling.container}>
          <div className={styling.top}>
            <h1>Premium</h1>
            <h2>8€ / Month</h2>
            <div className={styling.feature}>
              <TickSvg /> <span>Everything from Standard</span>
            </div>
            <div className={styling.feature}>
              <TickSvg /> <span>Get AI recommendations</span>
            </div>
            <div className={styling.feature}>
              <TickSvg /> <span>Use premade templates for your draft</span>
            </div>
            <div className={styling.feature}>
              <TickSvg /> <span>Add acts to structure your scenes</span>
            </div>
          </div>
          <button onClick={() => setShowModal(true)}>Sign up</button>
        </div>
      </div>
      <Modal show={showModal} title="Subscribe to the Newsletter" onExit={() => setShowModal(false)}>
        <MailjetSubscribeFrame />
      </Modal>
    </div>
  );
};
