import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { MessageType } from "../../redux/reducers/messageReducer";
import style from "./MessageDisplay.module.sass";

export const MessageDisplay = () => {
  const messages = useSelector((state) => state.messaging.messages);

  return (
    <div className={style.MessageDisplay}>
      {messages.map((msg) => (
        <label
          title={msg.text}
          className={classNames(
            style.toast,
            { [style.toast__success]: msg.type === MessageType.SUCCESS },
            { [style.toast__warning]: msg.type === MessageType.WARNING },
            { [style.toast__error]: msg.type === MessageType.ERROR }
          )}
        >
          {msg.text}
        </label>
      ))}
    </div>
  );
};
