import React from "react";

export const MailjetSubscribeFrame = () => {
  return (
    <iframe
      title="mailjet-subscribe-frame"
      className="mj-w-res-iframe"
      frameBorder="0"
      scrolling="no"
      marginHeight="0"
      marginWidth="0"
      src="https://app.mailjet.com/widget/iframe/6Bnf/IfM"
      width="100%"
    ></iframe>
  );
};
